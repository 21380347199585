<template>
  <div class="tally-item">
    <span class="tally-item__name font-sm">
      <el-checkbox v-if="check"></el-checkbox>
      {{ formatText(item) }}
    </span>
    <div v-if="divider" class="tally-item__divider"></div>
    <div :class="['tally-item__controls', { disabled: disableControls }]">
      <el-button
        :type="'control'"
        icon="eden-icon-minus"
        :disabled="disableControls"
        @click="subtract"
      />
      <span class="count">
        {{ count }}
      </span>
      <el-button
        :type="'control'"
        icon="eden-icon-add"
        :disabled="disableControls"
        @click="add"
      />
      <el-button v-if="allowRemove" plain @click="remove">
        <i class="eden-icon-delete"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LaundryItem",
  props: {
    item: {
      type: String,
      default: "",
    },
    count: {
      type: Number,
      default: null,
    },
    check: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: true,
    },
    disableControls: {
      type: Boolean,
      default: false,
    },
    allowRemove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    setCount: {
      get() {
        return this.count;
      },
      set(value) {
        this.$emit("update:count", value);
      },
    },
  },
  methods: {
    add() {
      this.setCount++;
    },
    subtract() {
      this.setCount <= 1
        ? this.$message.error("Tally cannot be less than 1")
        : this.setCount--;
    },
    remove() {
      this.$emit("remove");
    },
  },
};
</script>

<style lang="scss" scoped>
.tally-item {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__name {
    display: flex;
    align-items: center;
    min-width: 80px;
  }

  &__divider {
    width: calc(100% - 100px);
    margin: 0 10px;
    border-bottom: 1px dashed #cbd8d2;
  }

  &__controls {
    display: flex;
    align-items: center;
    width: 100px;

    .count {
      margin: 0 10px;
      font-size: 0.875rem;
    }

    .el-button {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      padding: 0;
      font-size: 10px;
      line-height: 14px;
      border: 0;
      justify-content: center;
      margin: 0 !important;

      &--control {
        background: var(--eden-grey-septenary);
      }

      &:last-child {
        i {
          color: var(--eden-red-primary);
          font-size: 1.125rem;
        }
      }
    }

    &.disabled {
      .count {
        color: var(--eden-grey-quinary);
      }
    }
  }
}
</style>
