<template>
  <el-dialog
    title="Resolve and Complete?"
    :visible.sync="setShow"
    @close="close"
    width="28%"
    append-to-body
  >
    <el-row type="flex">
      <el-col :span="24">
        <span class="font-base"
          >Share how this issue was resolved and who was involved in the
          process.</span
        >
      </el-col>
    </el-row>
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="How was this issue resolved?"
            prop="resolution"
            :rules="validateField()"
          >
            <el-input
              type="textarea"
              rows="3"
              v-model="form.resolution"
              placeholder=""
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item
            label="Who was involved in resolving this issue?"
            prop="members"
            :rules="validateField()"
          >
            <el-select
              v-model="form.members"
              filterable
              multiple
              value-key="id"
              placeholder="Search team member"
            >
              <el-option
                v-for="(member, index) in members"
                :key="index"
                :label="member.name"
                :value="member"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="members">
            <div
              v-for="(member, index) in form.members"
              :key="index"
              class="el-selected-member"
            >
              <div class="name">
                <eden-avatar :size="24" />
                <span>{{ member.name }}</span>
              </div>
              <div class="actions">
                <span class="close" @click="removeMember(member.id)">
                  <i class="eden-icon-remove-circle" />
                </span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        :type="'primary'"
        :disabled="disabled"
        :loading="resolving"
        @click="resolve"
        >Resolve issue and
        {{
          reporter === "customer" ? "close report" : "complete qc"
        }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import members from "@/requests/settings/members";
import laundry from "@/requests/orders/laundry";
import schedule from "@/requests/orders/schedule";

export default {
  name: "LaundryQcResolve",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    reportId: {
      type: [String, Number],
      default: "",
    },
    reporter: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        resolution: "",
        members: [],
      },
      members: [],
      resolving: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disabled() {
      return !this.form.resolution || !this.form.members.length;
    },
    orderId() {
      return this.$route.params.id;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.getMembers();
      }
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    getMembers() {
      members.list().then((response) => {
        const { status, data } = response.data;
        if (status && data.length) {
          this.members = data;
        }
      });
    },
    removeMember(id) {
      this.form.members = this.form.members.filter(
        (member) => member.id !== id
      );
    },
    resolve() {
      this.resolving = true;
      const payload = {
        report_id: this.reportId,
        resolution: this.form.resolution,
        resolved_by: this.form.members.map((member) => member.id),
        report_resolved: true,
      };
      laundry
        .qcReportUpdate(payload)
        .then((response) => {
          if (response.data.status) {
            this.$emit("resolved");
            if (this.reporter !== "customer") {
              this.updateQcStatus();
            }
            this.setShow = false;
          }
          this.resolving = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.resolving = false;
        });
    },
    updateQcStatus() {
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_ids: [this.orderId],
        order_status: this.type === "post_pickup" ? "pickup_qc" : "delivery_qc",
        event: this.type === "post_pickup" ? "pickup" : "delivery",
      };

      schedule
        .activity("laundry", payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.$message.success(message);
          }
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped>
.controls::before {
  display: none;
}

.members {
  max-height: 150px;
  overflow-y: scroll;
}
</style>
