<template>
  <div class="edit-order">
    <eden-overview-card :title="'Edit Order'">
      <template slot="action">
        <div class="edit-order__actions">
          <el-button @click="setLaundry"> Undo Changes </el-button>
          <el-button type="primary" :loading="updating" @click="updateOrder">
            Save Changes
          </el-button>
        </div>
      </template>
      <template slot="content">
        <div class="edit-order__banner" @click="$emit('toggle-edit')">
          <span class="font-sm">
            You are now editing this order.
            <span class="text-primary">
              Click here to exit edit mode without saving changes.
            </span>
          </span>
        </div>
        <div class="item">
          <el-form :model="form" ref="form" label-position="top">
            <el-row :type="'flex'">
              <el-col :span="24">
                <el-form-item
                  label="Laundry Type"
                  prop="laundry_type"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="form.laundry_type"
                    placeholder="Select"
                    :disabled="!allowAccessFor(['superadmin'])"
                  >
                    <el-option
                      v-for="(item, index) in laundryTypes"
                      :key="index"
                      :label="item.name"
                      :value="formatSlug(item.name)"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :type="'flex'" :gutter="20" class="is-flex-wrap">
              <el-col :md="8">
                <el-form-item
                  label="Quantity"
                  prop="quantity"
                  :rules="validateField()"
                >
                  <el-input
                    placeholder="Quantity"
                    v-model.number="form.quantity"
                  >
                    <template slot="append">Bag(s)</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="6">
                <el-form-item
                  label="Weight"
                  prop="weight"
                  :rules="validateField()"
                >
                  <el-input
                    placeholder="Enter weight"
                    v-model.number="form.weight"
                    v-decimal
                  >
                    <template slot="append">kg</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :md="10">
                <el-form-item label="Service Partner">
                  <el-select
                    v-model.number="form.sp_laundry"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="(sp, index) in laundryServicePartners"
                      :key="index"
                      :label="sp.name"
                      :value="sp.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <laundry-tally-edit :tally.sync="form.tally" />
              </el-col>
            </el-row>
          </el-form>
        </div>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import LaundryTallyEdit from "@/components/Orders/Order/Laundry/Tally/LaundryTallyEdit";

import laundry from "@/requests/orders/laundry";
import order from "@/requests/orders/order";

export default {
  name: "OrderLaundryEdit",
  components: { LaundryTallyEdit },
  props: {
    laundry: {
      type: Object,
      default() {
        return {};
      },
    },
    orderId: {
      type: [String, Number],
      default: null,
    },
    customerId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      form: {
        service: "Laundry",
        laundry_type: "",
        quantity: null,
        weight: null,
        sp_laundry: null,
        tally: [],
      },
      laundryTypes: [],
      updating: false,
    };
  },
  computed: {
    laundryServicePartners() {
      return this.$store.getters.service_partners_list.laundry;
    },
    totalLaundryTally() {
      return this.form.tally.reduce((total, item) => {
        return total + item.item_quantity;
      }, 0);
    },
    spSpecifics() {
      const payload = [
        {
          serviceProviderID: this.laundry.sp_laundry,
          specifics: this.laundry.laundry_type,
        },
      ];
      return { sp_specifics: JSON.stringify(payload) };
    },
  },
  created() {
    this.setLaundryTypes();
    this.setLaundry();
  },
  methods: {
    setLaundryTypes() {
      laundry
        .types()
        .then((response) => {
          if (response.data.status) {
            this.laundryTypes = response.data.data;
          }
        })
        .catch();
    },
    setLaundry() {
      const { customerId, laundry_type, weight, quantity, sp_laundry, tally } =
        this.laundry;
      this.form.customerId = customerId;
      this.form.laundry_type = laundry_type;
      this.form.quantity = quantity;
      this.form.sp_laundry = sp_laundry;
      this.form.weight = weight;
      this.form.tally = tally;
    },
    updateOrder() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        const payload = {
          id: this.orderId,
          orderPayload: this.form,
          spPayload: this.spSpecifics,
        };
        order
          .update(payload)
          .then((response) => {
            const orderUpdate = response[0].data;
            const spUpdate = response[1].data;
            if (orderUpdate.status && spUpdate.status) {
              this.updateOrderTally();
              this.$message.success("Order details updated successfully");
            }
            this.updating = false;
            this.$emit("updated", this.form);
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    updateOrderTally() {
      if (!this.form.tally.length) {
        return;
      }
      const payload = {
        tally_items: this.form.tally,
      };

      laundry
        .tallyUpdate(this.orderId, payload)
        .then((response) => {
          const { status } = response.data;
          if (status) {
            this.updating = false;
          }
        })
        .catch();
    },
  },
};
</script>

<style lang="scss" scoped></style>
