<template>
  <div>
    <div v-if="!editing">
      <eden-overview-card :title="'Order Items'">
        <template slot="action">
          <el-button
            :type="'text'"
            :color="'primary'"
            :disabled="disableUpdate"
            @click="editing = true"
          >
            Edit Order
          </el-button>
        </template>
        <template slot="content">
          <el-collapse>
            <eden-collapse-item
              :activities="laundry.activities"
              :wrap-bar="true"
            >
              <template slot="summary">
                <span class="type">
                  {{ formatToTitleCase(laundry.laundry_type) }}
                </span>
                <span class="status">{{
                  formatServiceActivity(order.last_marked_activity)
                }}</span>
              </template>
              <template slot="breakdown">
                <div>
                  <span class="font-sm text-grey-secondary is-block"
                    >{{ formatServiceUnit("laundry", laundry.quantity, true) }}
                    -
                    {{ weightAdded }}
                  </span>
                  <div class="mt-10">
                    <span v-if="spAssigned" class="font-sm">
                      {{ spAssigned }}
                    </span>
                    <span v-else class="font-sm text-uppercase text-danger">
                      No Sp Assigned
                    </span>
                  </div>
                </div>
                <div class="breakdown-section">
                  <laundry-tally
                    :label="'Items Tally'"
                    :tally="laundry.tally"
                  />
                </div>
              </template>
              <template slot="content">
                <laundry-activity
                  v-if="
                    laundry.activities.pickup_qc.status &&
                    !laundry.activities.sent_to_sp.status
                  "
                  class="mt-2"
                  :order-ids="[orderId]"
                  @updated="$emit('get-order')"
                />
              </template>
            </eden-collapse-item>
          </el-collapse>
        </template>
      </eden-overview-card>

      <order-address-information
        :type="'pickup'"
        :service="'laundry'"
        :deleted-status="deleted"
        :order="orderDeliveryInformation"
      />
      <order-address-information
        :type="'delivery'"
        :service="'laundry'"
        :deleted-status="deleted"
        :order="orderDeliveryInformation"
      />

      <eden-overview-card :title="'Quality Check'" v-if="!deleted">
        <template slot="content">
          <el-collapse>
            <laundry-qc
              :order="order"
              :type="'post_pickup'"
              :tally="laundry.tally"
              :activities="laundry.activities"
              @updated="$emit('get-order')"
            />
          </el-collapse>
          <el-collapse>
            <laundry-qc
              :order="order"
              :type="'pre_delivery'"
              :tally="laundry.tally"
              :activities="laundry.activities"
              @updated="$emit('get-order')"
            />
          </el-collapse>
        </template>
      </eden-overview-card>
    </div>
    <div v-else class="fadeIn">
      <order-laundry-edit
        :order-id="orderId"
        :customer-id="customerId"
        :laundry="laundry"
        @toggle-edit="editing = false"
        @updated="updateLaundry"
        @get-order="$emit('get-order')"
      />
    </div>
  </div>
</template>
<script>
import LaundryTally from "@/components/Orders/Order/Laundry/Tally/LaundryTally";
import OrderLaundryEdit from "@/components/Orders/Order/Laundry/OrderLaundryEdit";
import LaundryQc from "@/components/Orders/Order/Laundry/QualityCheck/LaundryQc.vue";
import OrderAddressInformation from "@/components/Orders/Order/Address/OrderAddressInformation";

import { LAUNDRY_ACTIVITIES } from "@/components/Orders/Order/Laundry/laundry.config";
import LaundryActivity from "@/components/Orders/Order/Laundry/LaundryActivity";

export default {
  name: "OrderLaundry",
  components: {
    LaundryActivity,
    OrderLaundryEdit,
    LaundryTally,
    LaundryQc,
    OrderAddressInformation,
  },
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: "1",
      laundry: {
        laundry_type: "",
        quantity: "",
        weight: null,
        sp_laundry: "",
        sp_name: "",
        tally: [],
        activities: LAUNDRY_ACTIVITIES(this.order),
      },
      editing: false,
      tallyHistory: false,
    };
  },
  computed: {
    deleted(){
      return this.order.customer_email === null ? true :false
    },
    orderId() {
      return this.order.order_id;
    },
    customerId() {
      return this.order.customer_id;
    },
    orderDeliveryInformation() {
      return {
        order_id: this.order.order_id,
        pickup_date: this.order.pickup_from_customer_date,
        delivery_date: this.order.deliver_to_customer_date,
        location_area: this.order.location_area,
        address: this.order.home_address,
        phone_number: this.order.phone_number,
      };
    },
    laundryServicePartners() {
      return this.$store.getters.service_partners_list.laundry;
    },
    showTallyHistory: {
      get() {
        return this.tallyHistory;
      },
      set(value) {
        this.tallyHistory = value;
      },
    },
    spAssigned() {
      const { sp_laundry } = this.laundry;
      return sp_laundry ? this.laundryServicePartners[sp_laundry]?.name : "";
    },
    weightAdded() {
      const { weight } = this.laundry;
      return weight ? `${weight}kg` : "No weight added";
    },
  },
  created() {
    this.setLaundry();
  },
  methods: {
    setLaundry() {
      const {
        customer_id,
        laundry_type,
        quantity,
        sp_laundry,
        weight,
        items_tally,
      } = this.order;
      this.laundry.customerId = customer_id;
      this.laundry.laundry_type = laundry_type;
      this.laundry.quantity = quantity;
      this.laundry.weight = weight;
      this.laundry.sp_laundry = sp_laundry;
      this.laundry.tally = items_tally;
    },
    updateLaundry(laundry) {
      this.$nextTick(() => {
        this.laundry = {
          ...this.laundry,
          ...laundry,
        };
        this.editing = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
