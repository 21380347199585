<template>
  <div>
    <el-button type="primary" @click="showConfirm = true"
      >Mark order as sent to SP</el-button
    >
    <eden-confirm-dialog
      :title="'Mark as sent to SP?'"
      :button-type="'success'"
      :button-text="'Mark'"
      :button-status="marking"
      :show.sync="showConfirm"
      @confirm="markOrder"
    >
      <p>
        Are you sure you want to mark this order as sent to the service partner?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import schedule from "@/requests/orders/schedule";

export default {
  name: "LaundryActivity",
  props: {
    laundryActivity: {
      type: String,
      default: "sent_to_sp",
    },
    laundryType: {
      type: String,
      default: "pickup",
    },
    orderIds: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showConfirm: false,
      marking: false,
    };
  },
  methods: {
    markOrder() {
      this.marking = true;
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_ids: this.orderIds,
        order_status: this.laundryActivity,
        event: this.laundryType,
      };

      schedule
        .activity("laundry", payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.$message.success(message);
            this.marking = false;
            this.showConfirm = false;
            this.$emit("updated");
          } else {
            this.$message.error(message);
            this.$emit("error");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.$emit("error");
        });
    },
  },
};
</script>
