<template>
  <div>
    <el-button class="mr-1" type="primary" @click="checkPreQcStatus">
      Confirm Quality Check
    </el-button>
    <eden-confirm-dialog
      :title="typeLabel.title"
      :button-type="'success'"
      :button-text="typeLabel.button"
      :button-status="confirming"
      :show.sync="showConfirm"
      @confirm="confirmQc"
    >
      <span class="font-base">{{ typeLabel.instruction }}</span>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import schedule from "@/requests/orders/schedule";

export default {
  name: "LaundryQcConfirm",
  props: {
    allowQcActions: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
    activities: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showConfirm: false,
      confirming: false,
    };
  },

  computed: {
    orderId() {
      return this.$route.params.id;
    },
    typeLabel() {
      const types = {
        post_pickup: {
          title: "Confirm Quality Check",
          instruction: "Are you sure you want to confirm quality check?",
          button: "Confirm",
        },
        pre_delivery: {
          title: "Complete Quality Check",
          instruction:
            "Are you sure you want to update the customer’s order and complete quality check?",
          button: "Update and complete",
        },
      };
      return this.type ? types[this.type] : types.post_pickup;
    },
  },
  methods: {
    checkPreQcStatus() {
      if (!this.tally.length) {
        this.$message.info({
          message: "You need to save items tally first.",
          duration: 4000,
        });
        return;
      }

      if (this.allowQcActions) {
        this.showConfirm = true;
      } else {
        const previousEvent =
          this.type === "post_pickup"
            ? "Pickup Rider Delivered"
            : "Received From Sp";
        this.$message({
          message: `You need to update the status of '${previousEvent}'`,
          duration: 4000,
        });
      }
    },
    confirmQc() {
      this.confirming = true;
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_ids: [this.orderId],
        order_status: this.type === "post_pickup" ? "pickup_qc" : "delivery_qc",
        event: this.type === "post_pickup" ? "pickup" : "delivery",
      };

      schedule
        .activity("laundry", payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.showConfirm = false;
            this.$message.success(message);
            this.confirming = false;
            this.$emit("updated");
          } else {
            this.$message.error(message);
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.$emit("error");
        });
    },
  },
};
</script>

<style lang="scss">
.laundry-quality--check {
  .breakdown {
    margin-top: 0 !important;
  }

  .instruction {
    display: inline-block;
    font-size: 0.8rem;
    margin-top: 10px;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .section {
    margin-top: 10px;
  }

  ul {
    margin-top: 0;
    color: #1d352a;
    padding: 0;
    list-style-position: inside;

    li {
      margin: 5px 0;
    }
  }

  .resolution {
    .label {
      color: var(--eden-grey-secondary);
      font-weight: 500;
      font-size: 0.775rem;
      display: block;
    }

    span {
      font-size: 0.875rem;
      color: var(--eden-grey-primary);
    }

    .members {
      margin-top: 10px;
      display: flex;

      .member {
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          line-height: 1;
          font-size: 0.825rem;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
