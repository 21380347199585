<template>
  <div>
    <div class="laundry-tally">
      <div class="laundry-tally__header">
        <span class="font-sm"> {{ title }} ({{ total }}) </span>
        <el-button type="text" @click="showLaundryItemAdd = true">
          <i class="eden-icon-add mr-10 text-primary"></i> Add new item
        </el-button>
      </div>
      <laundry-item
        v-for="(tally, index) in setTally"
        :key="index"
        :item="tally.item"
        :count.sync="tally.count"
        @remove="removeItem(index)"
      />
    </div>
    <laundry-item-add
      :show.sync="showLaundryItemAdd"
      :added-items="setTally"
      @added="updateTally"
    />
  </div>
</template>

<script>
import LaundryItemAdd from "@/components/Orders/Order/Laundry/Tally/LaundryItemAdd";
import LaundryItem from "@/components/Orders/Order/Laundry/Tally/LaundryItem";

export default {
  name: "LaundryTallyEdit",
  components: { LaundryItem, LaundryItemAdd },
  props: {
    title: {
      type: String,
      default: "Items Tally",
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      laundryTypes: [],
      showLaundryItemAdd: false,
      updating: false,
    };
  },
  computed: {
    total() {
      return this.tally.reduce((total, item) => {
        return total + item.count;
      }, 0);
    },
    setTally: {
      get() {
        return this.tally;
      },
      set(value) {
        this.$emit("update:tally", value);
      },
    },
  },
  methods: {
    removeItem(index) {
      this.setTally.splice(index, 1);
    },
    updateTally(items) {
      let currentTally = this.parseData(this.tally);
      this.setTally = currentTally.concat(items);
    },
  },
};
</script>

<style lang="scss" scoped>
.laundry-tally {
  &__header {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      font-weight: 500;
    }
  }
}
</style>
