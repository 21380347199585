<template>
  <div class="laundry-quality--check">
    <eden-collapse-item :class="{ 'remove-icon': checked }" :disabled="checked">
      <template slot="summary">
        <span class="type">
          {{ labels.text }}
          <el-tooltip
            class="tip"
            effect="dark"
            :content="labels.tooltip"
            placement="top"
          >
            <i class="el-icon-warning-outline" />
          </el-tooltip>
        </span>
        <span :class="['status', { 'text-danger': reported }]">
          <i
            v-if="checked || resolved"
            class="el-icon-success text-secondary font-md"
          ></i>
          {{ statusLabel }}
        </span>
      </template>
      <template>
        <template slot="content">
          <div class="laundry-quality--check">
            <div v-if="pending">
              <span class="instruction">
                {{ labels.instruction }}
              </span>
              <div class="actions">
                <laundry-qc-confirm
                  class="mb-1"
                  :order="order"
                  :type="type"
                  :allow-qc-actions="allowQcActions"
                  :tally="tally"
                  :activities="activities"
                  @updated="updateQcReport"
                />
                <laundry-qc-report
                  :order="order"
                  :type="type"
                  :allow-qc-actions="allowQcActions"
                  :tally="tally"
                  :activities="activities"
                  @updated="getQcReport"
                />
              </div>
            </div>
            <div v-else-if="report.status !== 'pending'">
              <div class="section">
                <span class="font-sm">Reason for issue:</span>
                <ul>
                  <li
                    v-for="(reason, index) in report.reason"
                    :key="index"
                    class="font-sm"
                  >
                    {{ reason }}
                  </li>
                </ul>
              </div>
              <div class="section">
                <laundry-tally
                  :label="
                    type.includes('pickup')
                      ? 'QC Items Tally'
                      : 'Items with Issues'
                  "
                  :tally="report.tally"
                />
              </div>
              <div
                v-if="report.status === 'resolved'"
                class="section resolution"
              >
                <span class="label">Resolution</span>
                <span>{{ report.resolution }}</span>
                <div class="members">
                  <div
                    v-for="(member, index) in report.resolved_by"
                    :key="index"
                    class="member"
                  >
                    <eden-avatar :size="18" />
                    <span>{{ member.user_name }}</span>
                  </div>
                </div>
              </div>
              <template v-if="report.status === 'reported'">
                <div v-if="type.includes('pickup')" class="actions">
                  <el-button type="primary" @click="showQcResolve = true">
                    Update customer order and complete quality check
                  </el-button>
                  <laundry-qc-tally-update
                    :tally="report.tally"
                    :report-id="report.id"
                    @updated="updateQcReport"
                  />
                </div>
                <div v-else-if="type.includes('delivery')" class="actions">
                  <el-button type="primary" @click="showQcResolve = true">
                    Update customer order and complete quality check
                  </el-button>
                </div>
              </template>
            </div>
          </div>
        </template>
      </template>
    </eden-collapse-item>
    <laundry-qc-resolve
      :show.sync="showQcResolve"
      :type="type"
      :report-id="report.id"
      @resolved="updateQcReport"
    />
  </div>
</template>

<script>
import LaundryTally from "@/components/Orders/Order/Laundry/Tally/LaundryTally";
import LaundryQcResolve from "./LaundryQcResolve";
import LaundryQcTallyUpdate from "./LaundryQcTallyUpdate";
import laundry from "@/requests/orders/laundry";
import LaundryQcConfirm from "@/components/Orders/Order/Laundry/QualityCheck/LaundryQcConfirm";
import LaundryQcReport from "@/components/Orders/Order/Laundry/QualityCheck/LaundryQcReport";

export default {
  name: "OrderLaundryQc",
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      default: "",
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
    activities: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    LaundryQcReport,
    LaundryQcConfirm,
    LaundryTally,
    LaundryQcTallyUpdate,
    LaundryQcResolve,
  },
  data() {
    return {
      showConfirmDialog: false,
      showPickupQcReport: false,
      showQcTallyUpdate: false,
      showDeliveryQcReport: false,
      showQcResolve: false,
      report: {
        status: "pending",
      },
      reporting: false,
      action: "",
      update: false,
      edit: false,
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    labels() {
      const labels = {
        post_pickup: {
          text: "Pre-QA (After Laundry Pickup)",
          tooltip:
            "This refers to the quality check that is done after laundry has been received from the customer",
          instruction:
            "Confirm that this laundry order has been successfully checked and can be sent to the SP.",
        },
        pre_delivery: {
          text: "Post-QA - (Before Laundry Delivery)",
          tooltip:
            "This refers to the quality check that is done before laundry is delivered to the customer.",
          instruction:
            "Confirm that this laundry order has been successfully checked and can be dispatched to the customer.",
        },
      };
      return labels[this.type];
    },
    statusLabel() {
      const labels = {
        checked: "Checked",
        resolved: "Issue Resolved",
        pending: "Pending",
        reported: "QC Issue Reported",
      };
      return labels[this.report.status];
    },
    pending() {
      return this.report.status === "pending";
    },
    checked() {
      return this.report.status === "checked";
    },
    resolved() {
      return this.report.status === "resolved";
    },
    reported() {
      return this.report.status === "reported";
    },
    allowQcActions() {
      if (this.tally.length === 0) {
        return false;
      }
      if (this.type === "post_pickup") {
        return this.activities.pickup_rider_delivered.status;
      } else {
        return this.activities.received_from_sp.status;
      }
    },
  },
  created() {
    this.getQcReport();
  },
  methods: {
    getQcReport() {
      this.loading = true;
      laundry
        .qcReportGet(this.orderId, this.type)
        .then((response) => {
          const { status, data } = response.data;
          if (status && data.length) {
            this.report = data[0];
            this.report.status = data[0].report_resolved
              ? "resolved"
              : "reported";
            this.report.tally = data[0].item_received;
          } else {
            this.setQcReportStatus();
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.loading = false;
        });
    },
    setQcReportStatus() {
      if (this.type === "post_pickup") {
        const pickupQc = this.activities.pickup_qc.status;
        if (pickupQc) {
          this.report.status = "checked";
        }
      } else {
        const deliveryQc = this.activities.delivery_qc.status;
        if (deliveryQc) {
          this.report.status = "checked";
        }
      }
    },
    updateQcReport() {
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss">
.laundry-quality--check {
  .breakdown {
    margin-top: 0 !important;
  }

  .instruction {
    display: inline-block;
    font-size: 0.8rem;
    margin-top: 10px;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .section {
    margin-top: 10px;
  }

  ul {
    margin-top: 0;
    color: #1d352a;
    padding: 0;
    list-style-position: inside;

    li {
      margin: 5px 0;
    }
  }

  .resolution {
    .label {
      color: var(--eden-grey-secondary);
      font-weight: 500;
      font-size: 0.775rem;
      display: block;
    }

    span {
      font-size: 0.875rem;
      color: var(--eden-grey-primary);
    }

    .members {
      margin-top: 10px;
      display: flex;

      .member {
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          line-height: 1;
          font-size: 0.825rem;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
