<template>
  <div>
    <el-button class="ml-1" type="plain" @click="show = true">
      Edit item tally
    </el-button>
    <el-dialog
      title="Edit Item Tally"
      :visible.sync="show"
      @close="close"
      width="30%"
    >
      <div class="edit-body">
        <span class="font-base">
          Enter the correct quantity of items per category.
        </span>
        <div class="mt-20">
          <laundry-tally-edit :tally.sync="form.tally" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="updating" @click="update"
          >Update item tally</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LaundryTallyEdit from "@/components/Orders/Order/Laundry/Tally/LaundryTallyEdit";
import laundry from "@/requests/orders/laundry";

export default {
  name: "LaundryQcTallyUpdate",
  props: {
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
    reportId: {
      type: [String, Number],
      default: "",
    },
  },
  components: { LaundryTallyEdit },
  data() {
    return {
      show: false,
      form: {
        tally: [],
      },
      updating: false,
    };
  },
  watch: {
    show() {
      if (this.show) {
        this.form.tally = this.tally;
      }
    },
  },
  methods: {
    close() {
      this.show = false;
    },
    update() {
      this.updating = true;
      const payload = {
        report_id: this.reportId,
        items_received: this.form.tally,
      };
      laundry
        .qcReportUpdate(payload)
        .then((response) => {
          if (response.data.status) {
            this.$emit("updated");
            this.show = false;
          }
          this.updating = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.updating = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
