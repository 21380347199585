<template>
  <div>
    <el-button type="danger" plain @click="checkPreQcStatus">
      Report an issue
    </el-button>
    <laundry-pickup-qc-report
      ref="qc-report"
      :show.sync="showPickupQcReport"
      :tally="tally"
      @report="reportQc"
    />
    <laundry-delivery-qc-report
      ref="qc-report"
      :show.sync="showDeliveryQcReport"
      :tally="tally"
      @report="reportQc"
    />
    <issue-form
      :show.sync="showIssueForm"
      :order-specific="true"
      :order="order"
    />
  </div>
</template>

<script>
import LaundryPickupQcReport from "./LaundryPickupQcReport";
import LaundryDeliveryQcReport from "./LaundryDeliveryQcReport";

import laundry from "@/requests/orders/laundry";
import IssueForm from "@/components/Feedback/Issues/IssueForm";

export default {
  name: "LaundryQcReport",
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
    allowQcActions: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
    activities: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    IssueForm,
    LaundryPickupQcReport,
    LaundryDeliveryQcReport,
  },
  data() {
    return {
      showPickupQcReport: false,
      showDeliveryQcReport: false,
      showIssueForm: false,
    };
  },

  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },
  methods: {
    checkPreQcStatus() {
      if (!this.tally.length) {
        this.$message.info({
          message: "You need to save items tally first.",
          duration: 4000,
        });
        return;
      }

      if (this.type === "post_pickup") {
        if (this.activities.pickup_rider_delivered.status) {
          this.checkReportForm();
        } else {
          this.$message({
            message:
              "You need to update the status of 'Pickup Rider Delivered'",
            duration: 4000,
          });
        }
      } else {
        if (this.activities.received_from_sp.status) {
          this.checkReportForm();
        } else {
          this.$message({
            message: "You need to update the status of 'Received From Sp'",
            duration: 4000,
          });
        }
      }
    },
    checkReportForm() {
      if (this.allowAccessForFeature("develop", ["superadmin", "admin"])) {
        this.showIssueForm = true;
      } else {
        this.type === "post_pickup"
          ? (this.showPickupQcReport = true)
          : (this.showDeliveryQcReport = true);
      }
    },
    reportQc(data) {
      const { reasons, items } = data;
      const payload = {
        order_id: this.orderId,
        report_type: this.type,
        reason: reasons,
        items_received: items,
      };
      laundry
        .qcReport(payload)
        .then((response) => {
          if (response.data.status) {
            this.$emit("updated");
            this.type === "post_pickup"
              ? (this.showPickupQcReport = false)
              : (this.showDeliveryQcReport = false);
          }
          this.reporting = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.reporting = false;
        });
    },
  },
};
</script>

<style lang="scss">
.laundry-quality--check {
  .breakdown {
    margin-top: 0 !important;
  }

  .instruction {
    display: inline-block;
    font-size: 0.8rem;
    margin-top: 10px;
  }

  .actions {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  .section {
    margin-top: 10px;
  }

  ul {
    margin-top: 0;
    color: #1d352a;
    padding: 0;
    list-style-position: inside;

    li {
      margin: 5px 0;
    }
  }

  .resolution {
    .label {
      color: var(--eden-grey-secondary);
      font-weight: 500;
      font-size: 0.775rem;
      display: block;
    }

    span {
      font-size: 0.875rem;
      color: var(--eden-grey-primary);
    }

    .members {
      margin-top: 10px;
      display: flex;

      .member {
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
          line-height: 1;
          font-size: 0.825rem;
        }

        &:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
