<template>
  <div>
    <el-dialog
      title="Add New Item"
      :visible.sync="setShow"
      @close="close"
      width="30%"
      append-to-body
    >
      <el-form :model="form" label-position="top" ref="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="" prop="items" :rules="validateField()">
              <template slot="label">
                <div class="label">
                  <p>Items</p>
                  <span
                    >Not on list?
                    <span @click="showLaundryItemForm = true">Add new</span
                    >.</span
                  >
                </div>
              </template>
              <el-select
                v-model="form.items"
                filterable
                multiple
                collapse-tags
                placeholder="Select an item"
                value-key="id"
              >
                <el-option
                  v-for="(item, index) in items"
                  :key="index"
                  :label="item.item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="items">
              <laundry-item
                v-for="(item, index) in form.items"
                :key="index"
                :item="item.item"
                :count.sync="item.count"
                :divider="false"
                @remove="removeItem(item.id)"
              />
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="close">Cancel</el-button>
        <el-button
          :type="'primary'"
          :disabled="form.items.length === 0"
          @click="addItems"
          >Add Item(s)</el-button
        >
      </span>
    </el-dialog>
    <laundry-items-form :show.sync="showLaundryItemForm" @success="getItems" />
  </div>
</template>

<script>
import LaundryItem from "@/components/Orders/Order/Laundry/Tally/LaundryItem";
import laundry from "@/requests/services/laundry";
import LaundryItemsForm from "@/components/Services/Laundry/LaundryItems/LaundryItemForm";

export default {
  name: "LaundryItemAdd",
  components: { LaundryItemsForm, LaundryItem },
  props: {
    show: {
      type: Boolean,
      default() {
        return {};
      },
    },
    addedItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {
        items: [],
      },
      items: [],
      showLaundryItemForm: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show() {
      if (this.show) {
        if (!this.items.length) {
          this.getItems();
        }
      }
    },
  },
  methods: {
    close() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    getItems() {
      laundry
        .listItems()
        .then((response) => {
          const { status, data } = response.data;

          if (status) {
            const added = this.addedItems.map((item) =>
              item.item.toLowerCase()
            );
            const items = data.filter((item) => {
              return !added.includes(item.name.toLowerCase());
            });

            this.items = this.sortArray({ data: items, property: "name" }).map(
              (item) => {
                return {
                  id: item.id,
                  item: item.name,
                  count: 1,
                };
              }
            );
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    removeItem(id) {
      this.form.items = this.form.items.filter((item) => item.id !== id);
    },
    addItems() {
      this.$emit("added", this.form.items);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.controls::before {
  display: none;
}

.items {
  max-height: 150px;
  overflow-y: scroll;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 4px;
    position: absolute;
    right: 0;
  }

  &::-webkit-scrollbar-track {
    background: var(--eden-grey-septenary);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--eden-green-primary);
    border-radius: 4px;
  }
}

.el-form-item__label {
  width: 100%;
}

.label {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 0.75rem;

    span {
      color: var(--eden-green-primary);
      font-weight: 500;
      cursor: pointer;
    }
  }
}
</style>
