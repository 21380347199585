<template>
  <div>
    <span class="label"> {{ label }} ({{ total }}) </span>
    <div class="items">
      <div v-for="(item, index) in tally" :key="index" class="item">
        <span>{{ formatText(item.item) }}</span>
        <span class="text-primary">{{ item.count }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LaundryTally",
  props: {
    label: {
      type: String,
      default: "",
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    total() {
      return this.tally.reduce((total, item) => {
        return total + Number(item.count);
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  color: var(--eden-grey-secondary);
  font-weight: 500;
  font-size: 0.775rem;
  margin-bottom: 12px;
}

.items {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .item {
    background: #f0f4f2;
    border-radius: 8px;
    color: var(--eden-grey-secondary);
    font-size: 0.75rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 5px 8px;

    span {
      &:last-child {
        margin-left: 24px;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
