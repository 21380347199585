<template>
  <el-dialog
    title="Report An Issue"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <div v-if="step === 1">
      <transition name="fade" mode="out-in">
        <div class="report-reasons report-issues">
          <span class="label">Select at least one reason for this issue:</span>
          <el-checkbox-group v-model="form.reasons">
            <el-checkbox
              v-for="(reason, index) in reasons"
              :key="index"
              :label="reason"
            />
          </el-checkbox-group>
        </div>
      </transition>
    </div>
    <div v-else-if="step === 2">
      <transition name="fade" mode="out-in">
        <div class="report-tally">
          <span class="back" @click="step = 1">
            <i class="eden-icon-arrow-left"></i>
            Back
          </span>
          <span class="label"
            >Use the checkbox to select the laundry items that have issues and
            edit their quantity as needed.</span
          >
          <el-checkbox-group
            v-model="form.selected_items"
            class="items-with--issues"
          >
            <el-checkbox
              v-for="(item, index) in tally"
              :key="index"
              :label="item.item"
            >
              <laundry-item
                :item="item.item"
                :count.sync="item.count"
                :disable-controls="!form.selected_items.includes(item.item)"
                :allow-remove="false"
              />
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </transition>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :disabled="disabled"
        :loading="reporting"
        @click="next"
        >{{ step === 1 ? "Next" : "Save and report issue" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import LaundryItem from "@/components/Orders/Order/Laundry/Tally/LaundryItem";

export default {
  name: "LaundryDeliveryQcReport",
  components: {
    LaundryItem,
  },
  props: {
    show: {
      type: Boolean,
      default() {
        return {};
      },
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      step: 1,
      form: {
        reasons: [],
        selected_items: [],
      },
      reasons: [
        "Wrong processing",
        "Missing item(s)",
        "Clothes with bad odour",
        "Damp item(s)",
        "Damaged item(s)",
        "Mismatched item(s)",
        "Stained item(s)",
        "Rumpled item(s)",
      ],
      reporting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disabled() {
      if (this.step === 1) {
        return !this.form.reasons.length;
      }
      return false;
    },
  },

  methods: {
    closeEvent() {
      this.setShow = false;
    },
    next() {
      if (this.step === 1) {
        this.step = 2;
      } else {
        this.save();
      }
    },
    save() {
      this.reporting = true;
      const report = {
        reasons: this.form.reasons,
        items: this.tally.filter((item) =>
          this.form.selected_items.includes(item.item)
        ),
      };
      this.$emit("report", report);
    },
  },
};
</script>

<style lang="scss">
.report-issues {
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;

    .el-checkbox {
      width: 35%;
    }
  }
}

.items-with--issues {
  .el-checkbox {
    width: 100%;

    &__label {
      width: 95%;
    }
  }
}
</style>
