<template>
  <el-dialog
    title="Report An Issue"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <div v-if="step === 1">
      <transition name="fade" mode="out-in">
        <div class="report-reasons">
          <span class="label"> Select a reason for this issue </span>
          <el-checkbox-group v-model="form.reasons">
            <el-checkbox
              v-for="(reason, index) in reasons"
              :key="index"
              :label="reason"
            />
          </el-checkbox-group>
        </div>
      </transition>
    </div>
    <div v-else-if="step === 2">
      <transition name="fade" mode="out-in">
        <div class="report-tally">
          <span class="back" @click="step = 1">
            <i class="eden-icon-arrow-left"></i>
            Back
          </span>

          <span class="label"
            >Enter the correct quantity of items per category.</span
          >
          <laundry-tally-edit :tally.sync="form.items" />
        </div>
      </transition>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :disabled="disabled"
        :loading="reporting"
        @click="next"
        >{{ step === 1 ? "Next" : "Save and report issue" }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import LaundryTallyEdit from "@/components/Orders/Order/Laundry/Tally/LaundryTallyEdit";
export default {
  name: "LaundryPickupQcReport",
  props: {
    show: {
      type: Boolean,
      default() {
        return {};
      },
    },
    tally: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    LaundryTallyEdit,
  },
  data() {
    return {
      step: 1,
      form: {
        reasons: [],
        items: [],
      },
      reasons: [
        "Some items were not inputted under the right categories.",
        "The customer sent more items that what they inputted.",
        "The customer sent fewer items that what they inputted.",
      ],
      reporting: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    disabled() {
      if (this.step === 1) {
        return !this.form.reasons.length && !this.form.items.length;
      }
      return false;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.form.items = this.tally;
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
    next() {
      if (this.step === 1) {
        this.step = 2;
      } else {
        this.save();
      }
    },
    save() {
      this.reporting = true;
      this.$emit("report", this.form);
    },
  },
};
</script>

<style lang="scss">
.report-reasons,
.report-tally {
  .label {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 12px;
  }
}
.report-reasons {
  .el-checkbox .el-checkbox__label {
    margin-bottom: 10px;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: var(--eden-grey-secondary);
  }
}

.report-tally {
  .back {
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    font-size: 0.875rem;
    cursor: pointer;

    i {
      font-size: 1.25rem;
    }
  }
}
</style>
